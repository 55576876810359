import React, { useState } from 'react';
import { SelectionField, InputField } from './custom-inputs/input-field';
import './watering-scheduler.sass';

enum Meridiem {
  AM = 'am',
  PM = 'pm',
}
const ScheduledTime = ({
  id,
  defaultHour,
  defaultMin,
  defaultMeridiem,
}: {
  id: number;
  defaultHour: number;
  defaultMin: number;
  defaultMeridiem: string;
  key: number;
}) => {
  const [hour, setHour] = useState(defaultHour);
  const [minute, setMinute] = useState(defaultMin);
  const [meridiem, setMeridiem] = useState(defaultMeridiem);

  const generateTimeString = time => {
    const timeString = time.toString().slice(-2);
    if (timeString.length === 1) {
      return `0${timeString}`;
    }

    return timeString;
  };

  const hourString = generateTimeString(hour);
  const minuteString = generateTimeString(minute);

  return (
    <div className="schedule-time-container">
      <div className="input-description">at</div>
      <InputField
        key={`hour-${id}`}
        value={hourString}
        onChange={setHour}
        isNumerical
      />
      <InputField
        key={`minute-${id}`}
        value={minuteString}
        onChange={setMinute}
        isNumerical
      />
      <SelectionField
        key={`meridiem-${id}`}
        value={meridiem}
        options={[Meridiem.AM, Meridiem.PM]}
        onSelect={setMeridiem}
      />
    </div>
  );
};

export default function WateringScheduler() {
  const [durationMinutes, setDurationMinutes] = useState(2);
  const [frequency, setFrequency] = useState(3);

  const schedules = [
    <ScheduledTime
      key={1}
      id={1}
      defaultHour={8}
      defaultMin={0}
      defaultMeridiem={Meridiem.AM}
    />,
    <ScheduledTime
      key={2}
      id={2}
      defaultHour={12}
      defaultMin={0}
      defaultMeridiem={Meridiem.PM}
    />,
    <ScheduledTime
      key={3}
      id={3}
      defaultHour={8}
      defaultMin={0}
      defaultMeridiem={Meridiem.PM}
    />,
  ];

  return (
    <div className="scheduler-container">
      <div className="scheduler-section">
        <div className="scheduler-heading">
          <div className="scheduler-label">Duration</div>
          <div className="divider" />
        </div>
        <div className="scheduler-input duration">
          <InputField
            key="watering-duration"
            value={durationMinutes}
            onChange={setDurationMinutes}
            isNumerical
            maxLength={2}
          />
          <div className="input-description">minutes</div>
        </div>
      </div>
      <div className="scheduler-section">
        <div className="scheduler-heading">
          <div className="scheduler-label">Schedule</div>
          <div className="divider" />
        </div>
        <div className="scheduler-input schedule">
          <SelectionField
            key="watering-frequency"
            value={frequency}
            onSelect={setFrequency}
            options={[1, 2, 3]}
          />
          <div className="input-description">times per day:</div>
        </div>
        {schedules.slice(0, frequency)}
      </div>
    </div>
  );
}
