import React from 'react';
import classNames from 'classnames';
import './info-stat.sass';

export enum InfoStats {
  LOCATION = 'location',
  WATER_LEVEL = 'waterLevel',
  STATUS = 'status',
}

export default function InfoStat({
  type,
  alignment,
  location,
  level,
  status,
}: {
  type: InfoStats;
  alignment?: string;
  location?: string;
  level?: number;
  status?: string;
}) {
  const detailsGroupClasses = classNames('details-group', {
    'right-align': alignment === 'right',
    'left-align': alignment === 'left',
  });
  const statusIndicatorClasses = classNames('status-indicators', {
    'status-good': status === 'good',
    'status-warning': status === 'warning',
    'status-critical': status === 'critical',
  });

  const INFO_STATS_MAP = {
    [InfoStats.LOCATION]: (
      <>
        <div className="label">LOCATION</div>
        <div className="location">{location}</div>
      </>
    ),
    [InfoStats.WATER_LEVEL]: (
      <>
        <div className="label">Water Level</div>
        <div className="level">
          <div className="level-bar" style={{ width: `${level}%` }} />
        </div>
      </>
    ),
    [InfoStats.STATUS]: (
      <>
        <div className="label">STATUS</div>
        <div className={statusIndicatorClasses}>
          <div className="status-icon critical" />
          <div className="status-icon warning" />
          <div className="status-icon good" />
        </div>
      </>
    ),
  };

  return <div className={detailsGroupClasses}>{INFO_STATS_MAP[type]}</div>;
}
