import React, { useState } from 'react';
import classNames from 'classnames';
import IconCarrot from '../../icons/carrot';
import './dropdown.sass';
import { InputField, SelectionField } from './input-field';
import { WallHomeData } from '../../app';

export enum DropDownType {
  WALL = 'wall setting',
  ACCOUNT = 'my account',
  PREFERENCE = 'app preference',
  MANAGE = 'manage users',
}

const WallSettings = ({ wallHomeData }: { wallHomeData: WallHomeData }) => {
  const { wallAddress, wallLocation, wallName } =
    wallHomeData || ({} as WallHomeData);

  return (
    <div>
      <InputField
        key="name"
        label="Name"
        value={wallName}
        onChange={() => {}}
      />
      <InputField
        key="address"
        label="Address"
        value={wallAddress}
        onChange={() => {}}
      />
      <div className="short-fields-group">
        <InputField
          key="location"
          label="Location"
          value={wallLocation}
          onChange={() => {}}
        />
        <SelectionField
          key="Type"
          label="Type"
          value="Interior"
          options={['Interior', 'Exterior']}
        />
      </div>
      <div className="short-fields-group">
        <InputField
          key="zones"
          label="No. of Zones"
          value="1"
          onChange={() => {}}
        />
        <SelectionField
          key="Layout"
          label="Layout"
          value="1x1"
          options={['1x1', '1x2', '2x2']}
        />
      </div>
      <InputField
        key="registration"
        label="Registration Key"
        value="21343-01"
        onChange={() => {}}
      />
    </div>
  );
};

export default function DropDown({
  label,
  type,
  className,
  wallHomeData,
}: {
  label?: string;
  type: DropDownType;
  className?: string;
  wallHomeData?: WallHomeData;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const fieldClassName = classNames('dropdown-field', {
    isExpanded,
  });

  const DROPDOWN_CONTENT = {
    [DropDownType.WALL]: <WallSettings wallHomeData={wallHomeData} />,
    [DropDownType.ACCOUNT]: '',
    [DropDownType.PREFERENCE]: '',
    [DropDownType.MANAGE]: '',
  };
  const handleSetExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const childComponent = isExpanded ? DROPDOWN_CONTENT[type] : '';
  return (
    <div key={label} className="dropdown-container">
      <div className={fieldClassName} onClick={handleSetExpanded}>
        <div className="field-heading-group">
          <IconCarrot />
          <div className="field-label">{label}</div>
        </div>
      </div>
      {childComponent}
    </div>
  );
}
