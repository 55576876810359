import React from 'react';

export default function IconProducts() {
  return (
    <svg
      className="icon icon-products"
      id="Group_61"
      data-name="Group 61"
      width="39.294"
      height="36.204"
      viewBox="0 0 39.294 36.204"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_33"
            data-name="Rectangle 33"
            width="39.294"
            height="36.204"
          />
        </clipPath>
      </defs>
      <g id="Group_61-2" data-name="Group 61" clipPath="url(#clipPath)">
        <path
          id="Path_138"
          data-name="Path 138"
          d="M97.047,7.275,88.82,10.513,69.932,3.088l7.5-2.949a2,2,0,0,1,1.463,0Z"
          transform="translate(-58.524 0)"
        />
        <path
          id="Path_139"
          data-name="Path 139"
          d="M23.457,32.827,4.568,25.4,14.1,21.662l18.889,7.425Z"
          transform="translate(-3.823 -18.128)"
        />
        <path
          id="Path_140"
          data-name="Path 140"
          d="M0,48.424l19.218,7.544v20.76L1.278,69.686A2.014,2.014,0,0,1,0,67.811Z"
          transform="translate(0 -40.524)"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M123.073,76.728V55.968l19.217-7.544V67.812a2.012,2.012,0,0,1-1.277,1.873Z"
          transform="translate(-102.996 -40.524)"
        />
      </g>
    </svg>
  );
}
