import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './auth/private-route';
import MainLayout from './common/main-layout';
import GenericReroute from './generic/reroute';
import HomePage from './home-page/home-page';
import Logout from './logout';
import ProductsPage from './products-page/products-page';
import ServicePage from './service-page/service-page';
import SettingsPage from './settings-page/settings-page';

export interface Sensors {
  waterUse: number;
  waterFlow: number;
  aqi: number;
  aqiText: string;
  healthScorePercent: number;
  healthScoreText: string;
  oxygenPercentage: number;
  temperatureFahrenheit: number;
}

export interface WallHomeData {
  sensors: Sensors;
  wallAddress: string;
  wallLocation: string;
  wallName: string;
  wallVideoFeed: string;
}

const API_URL = `${window.location.origin}/api/controller/0/sensors`;

export default function App() {
  const [wallHomeData, setWallHomeData] = useState<WallHomeData>(
    {} as WallHomeData
  );
  const intervalId = useRef(null);

  useEffect(() => {
    const fetchfn = async () => {
      await fetch(API_URL, {
        method: 'GET',
      })
        .then(response => response.json())
        .then(json => {
          setWallHomeData(json);
        })
        .catch(() => {
          // boop
        });
    };

    fetchfn();

    intervalId.current = setInterval(() => {
      fetchfn();
    }, 1000 * 10);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return (
    <Routes>
      <Route path="/logout" element={<Logout />} />
      {/* <Route errorElement={<ErrorPage />} /> */}
      <Route element={<PrivateRoute component={MainLayout} />}>
        <Route path="/home">
          <Route index element={<HomePage wallHomeData={wallHomeData} />} />
          {/* <Route path='settings' element={<SettingsPage />} /> */}
        </Route>
        <Route
          path="/service"
          element={<ServicePage wallHomeData={wallHomeData} />}
        />
        <Route path="/products" element={<ProductsPage />} />
        <Route
          path="/settings"
          element={<SettingsPage wallHomeData={wallHomeData} />}
        />
        <Route path="*" element={<GenericReroute />} />
      </Route>
    </Routes>
  );
}
