import React, { useEffect } from 'react';
import { logOut } from './auth/auth';

export default function Logout() {
  useEffect(() => {
    logOut();
  }, []);

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };
  return <div style={style}>You have been logged out</div>;
}
