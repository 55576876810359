import React from 'react';

export default function IconService() {
  return (
    <svg
      className="icon icon-service"
      xmlns="http://www.w3.org/2000/svg"
      width="38.539"
      height="39.294"
      viewBox="0 0 38.539 39.294"
    >
      <g id="noun-service-2786414" transform="translate(-141.396 -132.932)">
        <path
          id="Path_130"
          data-name="Path 130"
          d="M176.993,136.66a.5.5,0,0,0-.066.086l-2.047,3.5a.5.5,0,0,1-.444.237l-.721-.025h-.055a4.034,4.034,0,0,1-2.521-1.72.464.464,0,0,1,0-.5,21.942,21.942,0,0,0,2.37-3.943c.434-1.261-.7-1.336-.827-1.341h-.025a6.994,6.994,0,0,0-4.841,1.513c-2.108,1.76-2.673,4.205-2.708,6.8a5.193,5.193,0,0,1-1.538,3.827c-.444.444-11.431,11.416-12.434,12.52a4.588,4.588,0,0,1-3.338,1.422,6.353,6.353,0,0,0-4.024,1.447,6.2,6.2,0,0,0-2.269,4.054,6.45,6.45,0,0,0,.862,4.362.45.45,0,0,0,.076.091c.938.892,1.387.111,1.387.111l2.214-3.424a.474.474,0,0,1,.545-.2l1.846.6h0a.443.443,0,0,1,.187.116l.656.691a.459.459,0,0,1,.081.5,14.405,14.405,0,0,1-1.8,2.93c-1.054,1.291-.171,1.73,0,1.8h.04a6.186,6.186,0,0,0,5.859-2.178c1.719-2.41,1.4-3.661,1.427-5.5a4.3,4.3,0,0,1,1.6-3.051l.035-.035c.5-.5,10.367-10.382,12.3-12.389s3.313-1.735,3.429-1.709a7.258,7.258,0,0,0,3.106-.2,9.858,9.858,0,0,0,4.281-4.81c.847-2.229-.313-4.6-.923-5.738-.534-1.018-1.513-.071-1.72.166Z"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M181.025,192.988l-5.158-5.017-1.941-4.357-6.626-3.736-1.941,1.941,3.5,7.19,3.409,1.14,5.728,5.587Z"
          transform="translate(-22.956 -44.973)"
          fill="#6ABC5C"
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M634.27,638.73l-4.357,4.357a1.044,1.044,0,0,0,0,1.472l9.676,9.676a1.044,1.044,0,0,0,1.472,0L645.5,649.8a1.044,1.044,0,0,0,0-1.482l-9.757-9.581h0a1.044,1.044,0,0,0-1.472,0Z"
          transform="translate(-467.699 -484.256)"
          fill="#6ABC5C"
        />
      </g>
    </svg>
  );
}
