import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import './nav-menu.sass';
import IconHome from '../icons/home';
import IconService from '../icons/service';
import IconProducts from '../icons/products';
import IconSettings from '../icons/settings';

enum MenuTabs {
  HOME = 'home',
  SERVICE = 'service',
  PRODUCTS = 'products',
  SETTINGS = 'settings',
}

interface TabDetails {
  icon: ReactElement;
  label: string;
  location: string;
}

export default function NavMenu() {
  const menuTabs: Record<MenuTabs, TabDetails> = {
    [MenuTabs.HOME]: {
      icon: <IconHome />,
      label: 'Home',
      location: 'home',
    },
    [MenuTabs.SERVICE]: {
      icon: <IconService />,
      label: 'Service',
      location: 'service',
    },
    [MenuTabs.PRODUCTS]: {
      icon: <IconProducts />,
      label: 'Products',
      location: 'products',
    },
    [MenuTabs.SETTINGS]: {
      icon: <IconSettings />,
      label: 'Settings',
      location: 'settings',
    },
  };

  const MenuTab = ({ tab }: { tab: MenuTabs }) => {
    const { icon, label, location } = menuTabs[tab];

    const navClasses = ({ isActive }) => {
      return classNames('menu-item', { 'is-active': isActive });
    };

    return (
      <div className="tab-group" key={label}>
        <NavLink to={location} className={navClasses}>
          {icon}
          <div className="tab-label">{label}</div>
        </NavLink>
      </div>
    );
  };

  return (
    <div className="menu-container">
      <div className="menu-items">
        <MenuTab tab={MenuTabs.HOME} />
        <MenuTab tab={MenuTabs.SERVICE} />
        <MenuTab tab={MenuTabs.PRODUCTS} />
        <MenuTab tab={MenuTabs.SETTINGS} />
      </div>
    </div>
  );
}
