import React from 'react';

export default function IconDownArrow() {
  return (
    <svg
      className="icon icon-down-arrow"
      width="19.135"
      height="11.689"
      viewBox="0 0 19.135 11.689"
    >
      <g id="Group_49" transform="translate(-463.379 1356.067) rotate(-90)">
        <line
          id="Line_2"
          data-name="Line 2"
          y1="7.446"
          x2="7.446"
          transform="translate(1346.5 465.5)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x1="7.446"
          y1="7.446"
          transform="translate(1346.5 472.946)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}
