import React from 'react';
import { WallHomeData } from '../app';
import { logOut } from '../auth/auth';
import MainButton from '../common/buttons/main-button';
import DropDown, { DropDownType } from '../common/custom-inputs/dropdown';

import IconLogo from '../icons/logo';
import MainLogo from '../../images/logo.svg';
import './settings-page.sass';

export default function SettingsPage({
  wallHomeData,
}: {
  wallHomeData: WallHomeData;
}) {
  const handleSignOut = () => {
    logOut();
    window.location.reload();
  };

  return (
    <div className="settings-container">
      <div className="settings-header">Settings</div>
      <DropDown
        type={DropDownType.WALL}
        label="Wall Settings"
        wallHomeData={wallHomeData}
      />
      <DropDown type={DropDownType.ACCOUNT} label="My Account" />
      <DropDown type={DropDownType.PREFERENCE} label="App Preferences" />
      <DropDown type={DropDownType.MANAGE} label="Manage Users" />

      <div className="action">
        <MainButton onClick={handleSignOut} label="Sign out" />
      </div>
      <div className="app-info-container">
        <div className="app-version">App version 1.01</div>
        <img src={MainLogo} width="200" height="100" />
        <div className="company-info">
          <div className="info-message">
            Brought to you by
            <br />
            <br />
          </div>
          <div className="address">
            <strong>Smart Biotiles, Inc.</strong> <br />
            1225 4th St. #630 <br />
            San Francisco, CA 94158
          </div>
          {/*<div className="company-container">
            <IconLogo />
            <div className="address">
              Smart Biotiles, Inc. <br />
              1225 4th St. #630 <br />
              San Francisco, CA 94158
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
}
