import React from 'react';
import LoginPage from '../login-page/login';
import { getSession } from './auth';

export default function PrivateRoute({ component: Component }) {
  const isLogin = getSession();

  if (!isLogin) {
    return <LoginPage />;
  }

  return <Component />;
}
