import React from 'react';

export default function IconSettings() {
  return (
    <svg
      className="icon icon-settings"
      xmlns="http://www.w3.org/2000/svg"
      width="39.647"
      height="39.647"
      viewBox="0 0 39.647 39.647"
    >
      <g
        id="Group_132"
        data-name="Group 132"
        transform="translate(-287.005 -11.176)"
      >
        <g id="noun-more-289312" transform="translate(287.181 -414.239)">
          <path
            id="Path_129"
            data-name="Path 129"
            d="M445.239,440a5.239,5.239,0,1,0,5.239,5.239A5.245,5.245,0,0,0,445.239,440"
            transform="translate(-425.592)"
            fillRule="evenodd"
          />
        </g>
        <path
          id="noun-settings-716653-FFFFFF"
          d="M298.642,282.6l-.9,4.956a15.542,15.542,0,0,0-2.522,1.047l-4.16-2.861-5.339,5.339,2.876,4.145a15.468,15.468,0,0,0-1.047,2.522l-4.956.9V306.2l4.956.9a15.422,15.422,0,0,0,1.062,2.522l-2.891,4.145,5.339,5.339,4.145-2.861a15.579,15.579,0,0,0,2.537,1.047l.9,4.956h7.552l.9-4.956a15.473,15.473,0,0,0,2.522-1.047l4.144,2.861,5.34-5.339-2.876-4.145a15.435,15.435,0,0,0,1.047-2.508l4.97-.914v-7.552l-4.971-.9a15.453,15.453,0,0,0-1.033-2.507l2.862-4.16-5.34-5.339-4.144,2.862a15.479,15.479,0,0,0-2.522-1.047l-.9-4.956h-7.552Zm3.776,11.328a8.5,8.5,0,1,1-8.5,8.5A8.5,8.5,0,0,1,302.418,293.924Z"
          transform="translate(4.411 -271.42)"
        />
      </g>
    </svg>
  );
}
