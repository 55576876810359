import React from 'react';
import classNames from 'classnames';
import './input-field.sass';
import IconDownArrow from '../../icons/down-arrow';

const LETTERS_REGEX = /[a-zA-Z]/;

export function InputField({
  label,
  value,
  type,
  placeholder,
  className,
  onChange,
  isNumerical,
  maxLength,
}: {
  key: string;
  label?: string;
  value?: string | number;
  type?: string;
  placeholder?: string;
  className?: string;
  onChange: (event) => void;
  isNumerical?: boolean;
  maxLength?: number;
}) {
  const fieldClassName = classNames('input-field', className);

  const handleOnChange = e => {
    const fieldValue = e.target.value;
    if (isNumerical && LETTERS_REGEX.test(fieldValue)) {
      return;
    }
    if (!!maxLength) {
      const fieldValueTrimmed = fieldValue.toString().slice(maxLength * -1);
      onChange(fieldValueTrimmed);
      return;
    }
    onChange(e.target.value);
  };

  return (
    <div className="input-field-container">
      <div className="input-label">{label}</div>
      <div className={fieldClassName}>
        <input
          type={type || 'text'}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
}

export function TextAreaField({
  label,
  value,
  placeholder,
  className,
  onChange,
}: {
  label: string;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange: (event) => void;
  key: string;
}) {
  const fieldClassName = classNames('input-field', className);

  return (
    <div className="input-field-container">
      <div className="input-label">{label}</div>
      <div className={fieldClassName}>
        <textarea value={value} placeholder={placeholder} onChange={onChange} />
      </div>
    </div>
  );
}

export function SelectionField({
  label,
  options,
  value,
  className,
  onSelect,
}: {
  label?: string;
  options: (string | number)[];
  value: string | number;
  className?: string;
  onSelect?: (option) => void;
  key: string;
}) {
  const fieldClassName = classNames('input-field selection-field', className);

  const handleOnSelect = e => {
    e.stopPropagation();
    onSelect(e.target.value);
  };

  return (
    <div key={label} className="input-field-container selection">
      <div className="input-label">{label}</div>
      <div className={fieldClassName}>
        <IconDownArrow />
        <select value={value} onChange={handleOnSelect}>
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
