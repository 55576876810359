import React from 'react';

export default function IconExit() {
  return (
    <svg
      className="icon-exit"
      xmlns="http://www.w3.org/2000/svg"
      width="23.938"
      height="23.938"
      viewBox="0 0 23.938 23.938"
    >
      <path
        id="noun-close-1028419"
        d="M120.751,100.78l-8,8-8-8-3.967,3.967,8,8-8,8,3.967,3.967,8-8,8,8,3.967-3.967-8-8,8-8Z"
        transform="translate(-100.78 -100.78)"
      />
    </svg>
  );
}
