import Cookies from 'js-cookie';

export const AUTH_COOKIE = '__session';

export const getSession = () => {
  const jwt = Cookies.get(AUTH_COOKIE);
  let session;
  try {
    if (jwt) {
      const base64Url = jwt.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      session = JSON.parse(window.atob(base64));
    }
  } catch (error) {
    console.log(error);
  }

  return session;
};

export const logOut = () => {
  Cookies.remove(AUTH_COOKIE);
};

export const LOGIN_URL = '/api/auth/login';

export const authenticate = ({ username, password }) => {
  return fetch(LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ username, password }),
  })
    .then(response => response.json())
    .then(result => {
      const { access_token } = result;

      if (access_token) {
        const expDate = new Date();
        expDate.setTime(expDate.getTime() + 10 * 60 * 1000);

        Cookies.set(AUTH_COOKIE, access_token, { expires: expDate });

        window.location.reload();
      }
    });
};
