import React from 'react';

export default function IconAlert() {
  return (
    <svg
      className="icon-alert"
      xmlns="http://www.w3.org/2000/svg"
      width="24.943"
      height="29.005"
      viewBox="0 0 24.943 29.005"
    >
      <g id="Group_16" data-name="Group 16" transform="translate(-3.23 -1.574)">
        <g id="noun_notification_1824077" transform="translate(3.23 1.574)">
          <g id="Layer_19" data-name="Layer 19">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M39.352,28.44l-.187-.253h0s-3.479-4.746-3.479-8.318h0c0-3.1-2.387-5.771-5.692-6.734a2.547,2.547,0,0,0,.035-.4,2.83,2.83,0,0,0-5.657,0,2.548,2.548,0,0,0,.035.4c-3.306.963-5.692,3.633-5.692,6.734,0,3.572-3.479,8.329-3.479,8.329h0l-.187.253c-.87,1.192.145,2.732,1.807,2.732H37.546C39.222,31.171,40.222,29.642,39.352,28.44Z"
              transform="translate(-14.73 -10)"
              fill="#6ABC5C"
            />
            <path
              id="Path_2"
              data-name="Path 2"
              d="M42.1,82.1a6.1,6.1,0,0,0,6.1-6.1H36A6.1,6.1,0,0,0,42.1,82.1Z"
              transform="translate(-29.625 -53.09)"
              fill="#6ABC5C"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
