import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { AUTH_COOKIE, LOGIN_URL } from '../auth/auth';
import MainButton from '../common/buttons/main-button';
import { InputField } from '../common/custom-inputs/input-field';
import IconSage from '../icons/sage';
import AppLogo from '../../images/app-logo.svg';
import './login.sass';

export default function LoginPage() {
  const [username, setUserName] = useState('');
  const [password, setPassWord] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleSubmit = () => {
    return fetch(LOGIN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ username, password }),
    })
      .then(response => response.json())
      .then(result => {
        const { access_token, statusCode } = result;

        if (access_token) {
          const expDate = new Date();
          expDate.setTime(expDate.getTime() + 10 * 60 * 1000);

          Cookies.set(AUTH_COOKIE, access_token, { expires: expDate });
          window.location.reload();
        } else if (statusCode === 401) {
          setLoginError('Username or password not found. Please try again.');
        }
      });
  };

  const clearLoginError = () => {
    if (!!loginError) {
      setLoginError('');
    }
  };
  const handleSetUsername = name => {
    clearLoginError();
    setUserName(name);
  };

  const handleSetPassword = password => {
    clearLoginError();
    setPassWord(password);
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="logo-group">
          <img src={AppLogo} width="200" height="200" />
        </div>
        <div className="login-form">
          <div className="login-warning">
            <span>{loginError}</span>
          </div>
          <InputField
            key="username"
            label="Username"
            onChange={handleSetUsername}
          />
          <InputField
            key="password"
            label="Password"
            type="password"
            onChange={handleSetPassword}
          />
          <div className="action">
            <MainButton
              onClick={handleSubmit}
              disabled={!!loginError}
              label="Sign in"
            />
          </div>
          <div className="footer-container">
            <div className="link">Register</div>
            <div className="link">Reset Password</div>
          </div>
        </div>
      </div>
    </div>
  );
}
