import React, { ReactElement, useState } from 'react';
import { Sensors, WallHomeData } from '../app';
import IconDownArrow from '../icons/down-arrow';
import IconWaterUse from '../../images/icon-wateruse.svg';
import IconWaterFlow from '../../images/icon-waterflow.svg';
import IconAQI from '../../images/icon-aqi.svg';
import IconHealthScore from '../../images/icon-health.svg';
import IconOxygen from '../../images/icon-oxygen.svg';
import IconTemp from '../../images/icon-temp.svg';
import FPO from '../../images/fpo.png';
import InfoStat, { InfoStats } from '../walls-page/common/info-stat';
import './home-page.sass';

interface WallStat {
  className?: string;
  label: string;
  image: string;
  value: number | string | ReactElement;
  descriptor?: string;
}

export default function HomePage({
  wallHomeData,
}: {
  wallHomeData: WallHomeData;
}) {
  const [liveFeedLoaded, setLiveFeedLoaded] = useState(false);
  const { sensors, wallAddress, wallLocation, wallName, wallVideoFeed } =
    wallHomeData || ({} as WallHomeData);

  const handleSetFeedLoaded = () => {
    setLiveFeedLoaded(true);
  };

  const videoURL = `${window.location.origin}${wallVideoFeed}`;

  const {
    waterUse,
    waterFlow,
    aqi,
    aqiText,
    healthScorePercent,
    healthScoreText,
    oxygenPercentage,
    temperatureFahrenheit,
  } = sensors || ({} as Sensors);

  const wallStats: WallStat[] = [
    {
      label: 'WATER USE',
      value: waterUse,
      descriptor: 'Gal. Total',
      image: IconWaterUse,
    },
    {
      label: 'WATER FLOW',
      value: waterFlow,
      descriptor: 'GPM (Avg.)',
      image: IconWaterFlow,
    },
    {
      className: 'aqi',
      label: 'AQI',
      value: aqi,
      descriptor: aqiText,
      image: IconAQI,
    },
    {
      className: 'health',
      label: 'HEALTH SCORE',
      value: `${healthScorePercent}%`,
      descriptor: healthScoreText,
      image: IconHealthScore,
    },
    {
      label: 'OXYGEN',
      value: `${oxygenPercentage}%`,
      image: IconOxygen,
    },
    {
      label: 'TEMP.',
      value: <span>{temperatureFahrenheit}&deg;F</span>,
      image: IconTemp,
    },
  ];

  const Stat = ({ statDetails }: { statDetails: WallStat; key: string }) => {
    const { className, label, image, value, descriptor } = statDetails;
    return (
      <div className="snapshot-group">
        <div className="snapshot-label-group">
          <div className="snapshot-label">{label}</div>
        </div>

        <div className="snapshot-img">
          <img src={image} />
        </div>

        <div className="snapshot-content">
          <div className="snapshot-value">{value}</div>
          <div className={`snapshot-description ${className}`}>
            {descriptor}
          </div>
        </div>
      </div>
    );
  };
  let videoSectionContent = null;
  if (!liveFeedLoaded) {
    videoSectionContent = (
      <div className="loader-text">
        <img src={FPO} width="100%" />
      </div>
    );
  } else if (!wallVideoFeed) {
    videoSectionContent = <div>Error loading live feed. Try again later.</div>;
  }

  return (
    <div className="home-container">
      <div className="home-header-group">
        <div className="header">{wallName}</div>
        <div className="header-subtitle">{wallAddress}</div>
        <div className="header-stats">
          <InfoStat
            type={InfoStats.LOCATION}
            location={wallLocation}
            alignment="left"
          />
          <InfoStat type={InfoStats.STATUS} status="good" alignment="right" />
        </div>
      </div>
      <div className="home-content">
        <div className="video-container">
          {videoSectionContent}
          <div className="video-label">LIVE VIDEO</div>
          <img src={videoURL} onLoad={handleSetFeedLoaded} alt="live-feed" />
        </div>
      </div>
      <div className="home-stats-container">
        <div className="stats-header">
          <div className="stats-label">
            <span className="main">WellWall</span> Stats
          </div>
          <div className="stats-selector">
            Today{' '}
            <div>
              <IconDownArrow />
            </div>
          </div>
        </div>
        <div className="snapshots-container">
          {wallStats.map(stat => (
            <Stat statDetails={stat} key={stat.label} />
          ))}
        </div>
      </div>
    </div>
  );
}
