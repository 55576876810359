import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const VALID_PATHS = ['/home', '/service', '/products', '/more'];

export default function GenericReroute() {
  const { pathname } = useLocation();

  const pathsString = VALID_PATHS.join('|');
  const regstring = `^(${pathsString}).*`;
  const match = pathname.match(regstring);

  if (match !== null) {
    const path = match[1];
    return <Navigate to={path} replace />;
  } else {
    return <Navigate to="/home" replace />;
  }
}
