import React from 'react';

export default function IconHome() {
  return (
    <svg
      className="icon icon-home"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="39.294"
      viewBox="0 0 40 39.294"
    >
      <g id="noun-home-4846674" transform="translate(-14.087 -16.772)">
        <path
          id="Path_137"
          data-name="Path 137"
          d="M48.246,38.105V53.97a2.1,2.1,0,0,1-2.1,2.1H39.2V41.324H28.974V56.067H22.025a2.1,2.1,0,0,1-2.1-2.1V38.105H15.574a1.483,1.483,0,0,1-1.045-2.535L33.042,17.2a1.483,1.483,0,0,1,2.088,0L53.644,35.57A1.483,1.483,0,0,1,52.6,38.105H48.245Z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
