import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import NavMenu from './nav-menu';
import './main-layout.sass';
import IconAlert from '../icons/alert';
import IconDroplette from '../icons/droplette';
import MainButton from './buttons/main-button';
import IconExit from '../icons/exit';
import WateringScheduler from './watering-scheduler';

export default function MainLayout() {
  const [modalActive, setModalActive] = useState(false);

  const mainContainerClasses = classNames('main-container', {
    isModalActive: modalActive,
  });
  const modalClasses = classNames('watering-modal-container', {
    isActive: modalActive,
  });

  const handleOnModalIconClick = () => {
    setModalActive(true);
  };

  const handleExitModalClick = () => {
    setModalActive(false);
  };

  return (
    <div className={mainContainerClasses}>
      <div className="backdrop" />
      <div className={modalClasses}>
        <div className="modal-content">
          <button className="exit-button" onClick={handleExitModalClick}>
            <IconExit />
          </button>
          <div className="modal-label">Watering</div>
          <div className="modal-header">Zone 1</div>
          <WateringScheduler />
          <div className="actions-container">
            <MainButton label="Run Now" onClick={() => {}} />
            <MainButton
              label="Stop"
              className="button-stop"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
      <div className="main-content">
        <div className="nav-top">
          <div className="alert-icon-container">
            <IconAlert />
            <div className="alert-count">12</div>
          </div>
          <button
            className="watering-icon-container"
            onClick={handleOnModalIconClick}
          >
            <IconDroplette />
          </button>
        </div>
        <div className="page-content">
          <Outlet />
        </div>
        <NavMenu />
      </div>
    </div>
  );
}
