import React from 'react';
import './products-page.sass';
import classNames from 'classnames';
import climatePack from '../../images/smartpack-climate.svg';
import wellnessPack from '../../images/smartpack-wellness.svg';
import mediaPack from '../../images/smartpack-media.svg';
import utilizationPack from '../../images/smartpack-utilization.svg';
interface Products {
  label: string;
  unit: string;
  image: any;
}
export default function ProductsPage() {
  const mockProducts: Products[] = [
    {
      label: 'Climate',
      unit: 'PACK',
      image: climatePack,
    },
    {
      label: 'Wellbeing',
      unit: 'PACK',
      image: wellnessPack,
    },
    {
      label: 'Media',
      unit: 'PACK',
      image: mediaPack,
    },
    {
      label: 'Utilization',
      unit: 'PACK',
      image: utilizationPack,
    },
  ];

  const ProductPack = ({ product }: { product: Products; key: string }) => {
    const { label, unit, image } = product;
    const productClasses = classNames('product', { [label]: true });
    return (
      <div className="product-container">
        <div className="product-image-group">
          <img className={productClasses} src={image} alt={label} />
        </div>
        <div className="label-group">
          <div className="product-name">{label}</div>
          <div className="product-unit">{unit}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="products-page-container">
      <div className="products-header-content">
        <div className="products-header">Products</div>
        <div className="products-description">
          Enhance or upgrade your system with these additional SmartPacks™.
        </div>
      </div>
      <div className="products-content">
        {mockProducts.map(product => (
          <ProductPack product={product} key={product.label} />
        ))}
      </div>
    </div>
  );
}
