import React from 'react';
import classNames from 'classnames';
import './main-button.sass';

export default function MainButton({
  onClick,
  label,
  disabled,
  className = '',
}: {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  className?: string;
}) {
  const buttonClasses = classNames('main-button', { [className]: true });

  return (
    <button onClick={onClick} disabled={disabled} className={buttonClasses}>
      <span className="button-text">{label}</span>
    </button>
  );
}
