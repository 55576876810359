import React from 'react';

export default function IconDroplette() {
  return (
    <svg
      className="icon-droplette"
      id="Settings"
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
    >
      <rect
        id="Rectangle_13"
        data-name="Rectangle 13"
        width="46"
        height="46"
        fill="#f7f7f7"
        opacity="0"
      />
      <path
        id="noun-water-5436220"
        d="M197.77,56.814c-.358.379-8.77,9.347-8.77,14.993a9.229,9.229,0,1,0,18.458,0c0-5.646-8.412-14.614-8.77-14.993a.651.651,0,0,0-.918,0Zm-1.347,20.824a.631.631,0,0,1-.581.383.639.639,0,0,1-.248-.051,6.7,6.7,0,0,1-3.8-4.286A.632.632,0,1,1,193,73.327a5.431,5.431,0,0,0,3.086,3.482.632.632,0,0,1,.333.829Z"
        transform="translate(-171.746 -41.624)"
        fill="#00aae9"
      />
      <path
        id="noun-water-5436220-2"
        data-name="noun-water-5436220"
        d="M194.748,56.749C194.514,57,189,62.875,189,66.575a6.049,6.049,0,1,0,12.1,0c0-3.7-5.514-9.578-5.748-9.827a.426.426,0,0,0-.6,0ZM193.865,70.4a.413.413,0,0,1-.381.251.419.419,0,0,1-.163-.033,4.391,4.391,0,0,1-2.492-2.809.414.414,0,1,1,.795-.235,3.56,3.56,0,0,0,2.023,2.282.414.414,0,0,1,.218.544Z"
        transform="translate(-155.098 -49.624)"
        fill="#00aae9"
      />
      <path
        id="noun-water-5436220-3"
        data-name="noun-water-5436220"
        d="M193.131,56.714c-.169.179-4.131,4.4-4.131,7.063a4.348,4.348,0,0,0,8.7,0c0-2.66-3.963-6.884-4.131-7.063a.306.306,0,0,0-.432,0Zm-.635,9.81a.3.3,0,0,1-.274.18.3.3,0,0,1-.117-.024,3.156,3.156,0,0,1-1.791-2.019.3.3,0,1,1,.571-.169,2.559,2.559,0,0,0,1.454,1.64.3.3,0,0,1,.157.391Z"
        transform="translate(-178.098 -48.624)"
        fill="#00aae9"
      />
    </svg>
  );
}
