import React, { useState, useEffect } from 'react';
import { WallHomeData } from '../app';
import MainButton from '../common/buttons/main-button';
import {
  SelectionField,
  TextAreaField,
} from '../common/custom-inputs/input-field';
import './service-page.sass';

export default function ServicePage({
  wallHomeData,
}: {
  wallHomeData: WallHomeData;
}) {
  const [selectedWall, setSelectedWall] = useState('');
  const [category, setCategory] = useState('');
  const { wallName } = wallHomeData || {};

  useEffect(() => {
    if (wallName) {
      setSelectedWall(wallName);
    }
  }, [wallName]);

  return (
    <div className="service-container">
      <div className="service-header">Service</div>
      <div className="service-description">
        Please complete the form below to request service. Your account
        information will be automatically added to the support ticket.
      </div>
      <div className="service-form">
        <SelectionField
          label="Wall"
          key="Wall"
          value={selectedWall}
          options={[wallName, 'Wall 2', 'Wall 3']}
          onSelect={setSelectedWall}
        />
        <SelectionField
          label="Category"
          key="Category"
          value={category}
          options={['Installation / Setup', 'Something else']}
          onSelect={setCategory}
        />
        <TextAreaField
          label="Message"
          key="Message"
          className="service-textarea"
          onChange={() => {}}
        />
        <div className="action">
          <MainButton label="Send" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
}
